/* ------------- General expansion component ------------- */
.mat-expansion-panel {
  box-shadow: none !important;
  border-radius: 0 !important;
  background-color: transparent !important;
}

/* ------------- Deployment list expansion ------------- */
.mat-expansion-panel.deployment {
  .mat-expansion-panel-body {
    background-color: var(--surface);
    border-radius: 0;
  }

  .mat-expansion-panel-header:hover, .mat-expansion-panel-header:hover {
    background-color: var(--surface-hover) !important;
  }
}

.mat-expansion-panel {
  border-radius: 0;
  background-color: var(--surface) !important;
  &:hover {
    cursor: pointer;
    background: var(--surface-hover) !important;
  }
}

.mat-expansion-panel-header.mat-expanded {
  border-radius: 0;
  background-color: var(--surface) !important;
  &:hover {
    cursor: pointer;
    background: var(--surface-hover) !important;
  }
}

.mat-expansion-panel-header.mat-expanded,
.mat-expansion-panel-header.mat-expanded:hover {
  background-color: var(--background-hover);
}

/* ------------- details view expansion ------------- */
.mat-expansion-panel.details {
  background-color: transparent;
}
