@font-face {
  font-family: "Continental Stag Sans";
  src: url(../fonts/ContinentalStagSans-Book.otf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Continental Stag Sans";
  src: url(../fonts/ContinentalStagSans-Book-Italic.otf);
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Continental Stag Sans";
  src: url(../fonts/ContinentalStagSans-Medium.otf);
  font-weight: bold;
}

@font-face {
  font-family: "Continental Stag Sans";
  src: url(../fonts/ContinentalStagSans-Medium-Italic.otf);
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Continental Stag Sans";
  src: url(../fonts/ContinentalStagSans-Light.otf);
  font-weight: 200;
}

@font-face {
  font-family: "Continental Stag Sans";
  src: url(../fonts/ContinentalStagSans-Light-Italic.otf);
  font-weight: 200;
  font-style: italic;
}
