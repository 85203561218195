button.mat-accent[color="accent"] {
  background-color: black;
  color: white;
  font-weight: 400;
  letter-spacing: 0.8px;
  font-style: normal;
  border-radius: 0;
  &:hover {
    background-color: var(--palettes-tertiary-colors-700);
  }
  &:focus {
    background-color: var(--palettes-tertiary-colors-500);
  }
  &:disabled {
    background-color: var(--palettes-neutral-variant-colors-200);
    color: var(--on-disabled-container);
  }
}

button.mat-primary[color="primary"] {
  border-radius: 0;
  font-weight: 400;
  letter-spacing: 0.8px;
  font-style: normal;}

button.mat-warn[color="warn"] {
  background-color: var(--colors-error);
  font-weight: 400;
  letter-spacing: 0.8px;
  font-style: normal;  border-radius: 0;
}

button.device-action-btn {
  span.mat-mdc-button-touch-target {
    height: 24px;
    width: 24px;
  }
}
