mat-sidenav-container.sidenav-page {
  mat-sidenav {
    mat-nav-list {
      #caedge-logo {
        height: 48px;
        margin-top: 8px;
        margin-bottom: 12px;
        a {
          font-size: 16px !important;
          font-weight: 400;
          color: var(--palettes-neutral-variant-colors-50) !important;
        }

        mat-icon {
          margin: 12px 8px 12px 24px;
          width: 32px;
          height: 32px;
        }
      }

      mat-list-item.caedge-feature {
        height: 40px !important;
        a {
          font-size: 12px !important;
          color: var(--palettes-neutral-variant-colors-50) !important;
        }
        mat-icon {
          margin-left: 30px;
          margin-right: 14px;
        }
      }

      .feature-section {
        margin: 20px 0 8px 30px;
        color: var(--colors-text) !important;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;

        .not-expanded {
          margin-left: 6px;
        }
      }
    }
  }
}
