.mat-mdc-table .mdc-data-table__header-row {
  background-color: var(--surface);
}

.mat-mdc-form-field {
  font-size: 14px;
  width: 100%;
}

.mat-table {
  user-select: none !important;
  background-color: var(--surface) !important;
}

.mat-row {
  width: 100% !important;
  align-items: center;
}

mat-paginator {
  background-color: var(--surface) !important;
  margin-bottom: 24px !important;

  .mat-mdc-paginator-icon {
    color: var(--on-surface-grey);
  }

  .mat-mdc-paginator-icon[disabled] {
    color: var(--on-disabled-container);
  }
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: none !important;
}

.mat-mdc-form-field {
  font-size: 14px;
  width: 100%;
}
