/* You can add global styles to this file, and also import other style files */
@import "src/assets/theme/theme";
@import "src/assets/scss/components/mat-input/_mat-input.scss";
@import "src/assets/scss/components/mat-select/_mat-select.scss";
@import "src/assets/scss/components/mat-button/_mat-button.scss";
@import "src/assets/scss/components/mat-nav-list/_mat-list-item.scss";
@import "src/assets/scss/components/mat-menu/_mat-menu.scss";
@import "src/assets/scss/components/mat-dialog/_mat-dialog.scss";
@import "src/assets/scss/components/mat-expansion-panel/_mat-expansion-panel.scss";
@import "src/assets/scss/components/mat-checkbox/_mat-checkbox.scss";
@import "src/assets/scss/components/mat-hint/_mat-hint.scss";
@import "src/assets/scss/components/mat-divider/_mat-divider.scss";
@import "src/assets/scss/components/mat-tooltip/_mat-tooltip.scss";
@import "src/assets/scss/components/mat-tab/_mat-tab.scss";
@import "src/assets/scss/components/mat-stepper-expanded/_mat-stepper-expanded";
@import "src/assets/scss/components/mat-table/_mat-table.scss";
@import "src/assets/scss/components/mat-icon/_mat-icon.scss";
@import "src/assets/scss/components/mat-chips/_mat-chips.scss";

body,
html {
  margin: 0;
  padding: 0;
}

input:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--palettes-red-colors-50);
}

@mixin font-theme($fontFamily, $color: null) {
  font-family: $fontFamily, Arial, sans-serif;

  @if $color {
    color: $color;
  }
}

body,
input,
textarea,
button {
  @include font-theme("Continental Stag Sans");
}

h1, h2, h3, h4, h5, h6 {
  @include font-theme("Continental Stag Sans", var(--on-background));
}

p {
  @include font-theme("Continental Stag Sans", var(--on-background));
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.header-sticky {
  position: sticky;
  top: -2px;
  margin-left: -1px;
  background-color: var(--background);
  height: 50px;
  z-index: 20;
}

.header p {
  color: var(--on-background-container);
  margin: 0;
  font-size: 24px;
  line-height: 33px;
}

.container {
  background: var(--surface);
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    margin: 0;
    color: var(--on-background-grey);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.8px;
  }
}

.header > .title-wrapper {
  margin-bottom: 12px;
}

@mixin button-theme($bgColor, $color, $hoverColor) {
  background-color: $bgColor;
  color: $color;

  &:hover {
    background-color: $hoverColor;
  }
}

.cancel-btn, .primary-btn, .tertiary-btn {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.8px;
  text-align: center;
  border-radius: 0;
  border: none;
  height: 40px;
  padding: 0 24px;
  cursor: pointer;
}

.primary-btn {
  @include button-theme(var(--primary), var(--on-primary), var(--primary-hover));
}

.tertiary-btn {
  @include button-theme(var(--tertiary), var(--on-tertiary), var(--tertiary-hover));
}

.disabled-btn {
  background-color: var(--disabled-container) !important;
  color: var(--on-disabled-container) !important;
  cursor: default !important;
}

.error-btn {
  @include button-theme(var(--error), var(--on-error), var(--error-hover));
}

.cancel-btn {
  @include button-theme(var(--surface), var(--on-surface), var(--surface-hover));
}

//------ Snackbar --------
.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  margin-top: 50px !important;
  border-radius: 0 !important;
}

.mat-mdc-snack-bar-container {
  &.success-snackbar {
    --mdc-snackbar-container-color: var(--okay);
    --mdc-snackbar-supporting-text-color: var(--on-okay);
  }
  &.error-snackbar {
    --mdc-snackbar-container-color: var(--error);
    --mdc-snackbar-supporting-text-color: var(--on-error);
  }
  &.info-snackbar {
    --mdc-snackbar-container-color: var(--info);
    --mdc-snackbar-supporting-text-color: var(--on-info);
  }
  &.warn-snackbar {
    --mdc-snackbar-container-color: var(--warning);
    --mdc-snackbar-supporting-text-color: var(--on-warning);
  }
}

