.caedge-menu,
.caedge-secondary-menu {
  div.mat-mdc-menu-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 8px;
    background-color: var(--surface-container);
    color: var(--on-surface-container);
    .caedge-menu-item {
      border-radius: 4px;
      min-height: 32px;
      background-color: var(--surface-container);
      span {
        color: var(--on-surface-container);
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
      }
    }
    .caedge-menu-item:hover {
      background-color: var(--surface-container-hover);
    }
    .caedge-menu-item:disabled {
      background-color: transparent;
      color: var(--on-disabled-container);
    }
  }
}

.caedge-secondary-menu {
  border-radius: 0 !important;
  div.mat-mdc-menu-content {
    background-color: var(--surface-container);
    mat-divider {
      background-color: var(--palettes-tertiary-colors-500);
    }
    .caedge-secondary-menu-item,
    .caedge-secondary-menu-active-item {
      border-radius: 4px;
      min-height: 32px;
      span {
        color: var(--palettes-neutral-variant-colors-50);
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
      }
      svg {
        fill: var(--palettes-neutral-variant-colors-50);
        height: 7px;
        width: 4px;
      }
    }
    .caedge-secondary-menu-active-item {
      background-color: #2aa26c;
      padding-right: 10px;
      .mdc-list-item__primary-text {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      span {
        color: black;
      }
    }
    .caedge-secondary-menu-active-item:hover {
      background-color: var(--palettes-green-colors-600) !important;
    }
    .caedge-secondary-menu-item:hover {
      background-color: var(--surface-container-hover);
    }
  }
}

.mat-mdc-menu-content {
  background-color: var(--background);
}

.mat-mdc-menu-item {
  color: var(--on-background) !important;
}
.mdc-list-item__primary-text {
  font-size: 14px !important;
}
