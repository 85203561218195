
// ------- OUTLINED ------- //
mat-form-field.caedge-form-field-outline {
  .mat-mdc-text-field-wrapper {
    border-top: 1px solid var(--tertiary);
    border-left: 4px solid var(--tertiary);
    border-right: 1px solid var(--tertiary);
    border-bottom: 1px solid var(--tertiary);
    border-radius: 0;
  }

  .mat-label {
    color: var(--on-background);
  }

  .mat-mdc-text-field-wrapper {
    background-color: var(--background) !important;
  }

  .mat-mdc-text-field-wrapper:focus {
    background-color: var(--background);
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: var(--background);
  }

  .mdc-text-field--focused {
    border: 1px solid var(--primary);
    border-left-width: 4px;
    background-color: transparent !important;
  }

  .mdc-text-field--disabled {
    background-color: var(--disabled-container);
  }
}

mat-form-field.mat-form-field-disabled.caedge-form-field-outline {
  .mat-mdc-text-field-wrapper {
    background-color: var(--disabled-container) !important;
    border-top: 1px solid var(--disabled-container);
    border-left: 4px solid var(--disabled-container);
    border-right: 1px solid var(--disabled-container);
    border-bottom: 1px solid var(--disabled-container);
  }
}

mat-form-field.mat-form-field-invalid.caedge-form-field-outline {
  .mat-mdc-text-field-wrapper {
    border: 1px solid var(--error);
    border-left: 4px solid var(--error);
  }
}

// ------- FILL ------- //
mat-form-field.caedge-form-field-fill {
  .mat-mdc-text-field-wrapper {
    border-left: 4px solid var(--tertiary);
    border-radius: 0;
  }

  .mat-label {
    color: var(--on-background);
  }

  .mat-mdc-text-field-wrapper {
    background-color: var(--surface) !important;
  }

  .mat-mdc-text-field-wrapper:focus {
    background-color: var(--background);
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: var(--background);
  }

  .mdc-text-field--focused {
    border-left: 4px solid var(--primary);
  }
}

mat-form-field.mat-form-field-disabled.caedge-form-field-fill {
  .mat-mdc-text-field-wrapper {
    background-color: var(--disabled-container) !important;
    border-left: 4px solid var(--on-disabled-container);
  }
}

mat-form-field.mat-form-field-invalid.caedge-form-field-fill {
  .mat-mdc-text-field-wrapper {
    background-color: var(--error-container) !important;
    border-left: 4px solid var(--error);
  }
}

// disabled the ripple underline effect on select,
// can have side effects on other material components
div[matformfieldlineripple=""] {
  display: none !important;
}

mat-form-field.caedge-login-input {
  width: 100%;
  margin-top: 10px;
  .mat-label {
    color: var(--on-background-grey);
  }

  .mat-mdc-text-field-wrapper {
    .mat-mdc-input-element {
      color: var(--palettes-red-colors-50);
      font-size: 16px;
      line-height: 24px;
    }
  }

  .mdc-text-field {
    border-radius: 0;
    background-color: transparent;
  }

  .mat-mdc-text-field-wrapper:hover {
    .mat-mdc-form-field-flex {
      .mat-mdc-text-field-wrapper {
        border-top: 1px solid var(--palettes-conti-yellow-colors-500) !important;
        border-left: 1px solid var(--palettes-conti-yellow-colors-500) !important;
        border-right: 1px solid var(--palettes-conti-yellow-colors-500) !important;
      }
    }
  }
}

.mdc-menu-surface.mat-mdc-select-panel{
  background-color: var(--background) !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

mat-checkbox.remember-me-cbx {
  .mdc-form-field {
    label {
      color: white;
    }

    .mdc-checkbox {
      margin-left: -10px;

      .mdc-checkbox__ripple {
        display: none;
      }

      .mdc-checkbox__background {
        border-color: white !important;
      }

      .mdc-checkbox--selected {
        border-color: var(--palettes-conti-yellow-colors-500);
      }

      .mdc-checkbox__native-control:enabled:checked {
        ~ .mdc-checkbox__background {
          border-color: var(--palettes-conti-yellow-colors-500) !important;

          svg {
            color: white;
          }
        }
      }
    }
  }
}
