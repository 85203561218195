.expanded-stepper .mat-vertical-stepper-content{
  visibility: visible !important;
  height: 100% !important;
}

.mat-vertical-stepper-header .mat-step-icon {
  background-color: var(--secondary) !important;
  color: var(--on-secondary) !important;
}

mat-vertical-stepper.no-click .mat-step-header{
  padding-bottom: 5px !important;
  cursor: unset !important;
  pointer-events: none !important;
}

.expanded-stepper .mat-step-label{
  font-size: 16px !important;
}

mat-vertical-stepper {
  background-color: var(--surface) !important;
}

.vecu-stepper .mat-vertical-stepper-header .mat-step-icon {
  font-weight: 800;
}

