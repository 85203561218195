mat-checkbox.mat-mdc-checkbox-disabled {
  div.mdc-form-field {
    div.mdc-checkbox {
      div.mdc-checkbox__background {
        background-color: var(
          --on-disabled-container
        ) !important;
      }
    }
    label {
      color: var(--on-disabled-container) !important;
    }
  }
}

.mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background,
.mdc-checkbox
  .mdc-checkbox__native-control:enabled:indeterminate
  ~ .mdc-checkbox__background,
.mdc-checkbox
  .mdc-checkbox__native-control[data-indeterminate="true"]:enabled
  ~ .mdc-checkbox__background {
  border-color: var(--secondary) !important;
  background-color: var(--secondary) !important;
  color: var(--on-secondary) !important;
}

mat-checkbox.mat-checkbox.mat-checkbox-indeterminate {
  background-color: var(--secondary-container) !important;
  border-color: var(--secondary-container) !important;
  color: var(--on-secondary) !important;
}

mat-checkbox.mat-checkbox.mat-checkbox-indeterminate:hover {
  background-color: var(--secondary-hover) !important;
  border-color: var(--secondary-hover) !important;
  color: var(--on-secondary) !important;

}

.mdc-checkbox__native-control {
  background-color: var(--secondary) !important;
  border-color: var(--secondary) !important;
  color: var(--on-secondary) !important;

}

.mdc-checkbox__checkmark {
  background-color: var(--secondary) !important;
  border-color: var(--secondary) !important;
  color: var(--on-secondary) !important;
}




.mdc-checkbox__mixedmark {
  background-color: var(--secondary-container) !important;
  border-color: var(--secondary-container) !important;
  color: var(--on-secondary-container) !important;
}

.mdc-checkbox__mixedmark:hover {
  background-color: var(--secondary-container-hover) !important;
  border-color: var(--secondary-container-hover) !important;
  color: var(--on-secondary-container) !important;
}

.input-partitions {
  mat-checkbox label {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    letter-spacing: 0.5px !important;
    text-align: left !important;
  }
  mat-checkbox.mat-mdc-checkbox-checked {
      label {
        color: var(--secondary) !important;
      }
    }
  }

