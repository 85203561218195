mat-dialog {
  background-color: var(--background);
}

.mat-mdc-dialog-title mdc-dialog__title {
  background-color: transparent;
}

.mdc-dialog__container {
  .mat-mdc-dialog-surface.mdc-dialog__surface{
    border-radius: 0;
    background-color: var(--background);
    .mat-mdc-dialog-title {
      padding: 0 16px 8px 16px;
      font-size: 16px;
      line-height: 24px;
      color: var(--on-background);
      letter-spacing: 0.15px;
      font-weight: 600;
      background-color: var(--background) !important;
    }
    .mat-mdc-dialog-content {
      padding: 4px 16px;
      font-size: 16px;
      line-height: 24px;
      color: var(--on-background);
      letter-spacing: 0.5px;
      overflow-wrap: break-word;
      white-space: pre-line;
      background-color: var(--background);
      .secondary-text {
        color: var(--on-background-grey);
        margin-top: 34px;
      }
    }
    .mat-mdc-dialog-actions {
      padding: 16px;
      background-color: var(--background);
      button {
        border-radius: 0;
        font-weight: 600;
      }
      button:nth-child(1) {
        background-color: transparent;
      }
    }
  }
}
