@use "@angular/material" as mat;
@import "./colors";
@import "./fonts.scss";

@include mat.core();

$continental-font-family: "Continental Stag Sans" !default;

$primary-palette: mat.m2-define-palette($primary-colors);
$accent-palette: mat.m2-define-palette($accent-colors);
$warn-palette: mat.m2-define-palette($warn-colors);

$continental-typography: mat.m2-define-typography-config(
  $font-family: $continental-font-family,
);

$light-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $primary-palette,
      accent: $accent-palette,
      warn: $warn-palette,
    ),
    typography: $continental-typography,
    density: 0,
  )
);

$dark-theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: $primary-palette,
      accent: $accent-palette,
      warn: $warn-palette,
    ),
    typography: $continental-typography,
    density: 0,
  )
);

@include mat.all-component-themes($light-theme);

// Use these variables to enable light or dark mode in your components
// Usage is 'css-property: var(--color-variable-name);'
body {
  // Light mode specific colors
    --primary: #ffa500;
    --on-primary: #ffffff;
    --primary-hover: #e59600;
    --primary-pressed: #cc8400;
    --primary-focused: #b27500;
    --primary-container: #ffdb99;
    --on-primary-container: #4d3200;
    --primary-container-hover: #ffd380;
    --primary-container-pressed: #ffc966;
    --primary-container-focused: #ffc14d;
    --secondary: #666bff;
    --on-secondary: #ffffff;
    --secondary-hover: #777bff;
    --secondary-pressed: #888bff;
    --secondary-focused: #999cff;
    --secondary-container: #bbbdff;
    --on-secondary-container: #0c0e5a;
    --secondary-container-hover: #aaacff;
    --secondary-container-pressed: #999cff;
    --secondary-container-focused: #888bff;
    --tertiary: #252527;
    --on-tertiary: #ffffff;
    --tertiary-hover: #323234;
    --tertiary-pressed: #3e3e41;
    --tertiary-focused: #4a4a4f;
    --tertiary-container: #e5e5e6;
    --on-tertiary-container: #323234;
    --tertiary-container-hover: #d8d8da;
    --tertiary-container-pressed: #cbcbcd;
    --tertiary-container-focused: #bebec1;
    --background: #fafafa;
    --on-background: #22222a;
    --on-background-grey: #818197;
    --background-hover: #f1f1f3;
    --background-pressed: #e3e3e8;
    --background-focused: #d5d5dc;
    --background-container: #f1f1f3;
    --on-background-container: #22222a;
    --on-background-container-grey: #73738c;
    --background-container-hover: #e3e3e8;
    --background-container-pressed: #d5d5dc;
    --background-container-focused: #c7c7d1;
    --surface: #f0f0f4;
    --on-surface: #2b2b3b;
    --on-surface-grey: #575775;
    --surface-hover: #e2e2e9;
    --surface-pressed: #d3d3de;
    --surface-focused: #c4c4d4;
    --surface-container: #2b2b3b;
    --on-surface-container: #f0f0f4;
    --on-surface-container-grey: #a7a7be;
    --surface-container-hover: #363649;
    --surface-container-pressed: #414158;
    --surface-container-focused: #4c4c67;
    --disabled-container: #d3d3de;
    --on-disabled-container: #575775;
    --error: #e83030;
    --on-error: #ffffff;
    --error-hover: #e51a1a;
    --error-pressed: #cf1717;
    --error-focused: #b81414;
    --error-container: #fad1d1;
    --on-error-container: #5c0a0a;
    --error-container-hover: #f7baba;
    --error-container-pressed: #f5a3a3;
    --error-container-focused: #f28c8c;
    --warning: #f2c40d;
    --on-warning: #302703;
    --warning-hover: #dab10b;
    --warning-pressed: #c29d0a;
    --warning-focused: #aa8909;
    --warning-container: #fbedb6;
    --on-warning-container: #493b04;
    --warning-container-hover: #fae89e;
    --warning-container-pressed: #f9e286;
    --warning-container-focused: #f7dc6e;
    --info: #428bf0;
    --on-info: #ffffff;
    --info-hover: #2a7dee;
    --info-pressed: #136eec;
    --info-focused: #1163d5;
    --info-container: #d0e2fb;
    --on-info-container: #072c5f;
    --info-container-hover: #b8d3f9;
    --info-container-pressed: #a0c5f7;
    --info-container-focused: #89b6f6;
    --okay: #29a38f;
    --on-okay: #ffffff;
    --okay-hover: #248f7d;
    --okay-pressed: #1f7a6b;
    --okay-focused: #1a6659;
    --okay-container: #adebe0;
    --on-okay-container: #0f3d36;
    --okay-container-hover: #99e5d9;
    --okay-container-pressed: #85e0d1;
    --okay-container-focused: #70dbc9;
}

body.dark-mode {
  @include mat.all-component-colors($dark-theme);
  // Dark mode specific colors
    --primary: #ffb733;
    --on-primary: #332100;
    --primary-hover: #ffae1a;
    --primary-pressed: #ffa500;
    --primary-focused: #e59600;
    --primary-container: #b27500;
    --on-primary-container: #ffffff;
    --primary-container-hover: #996300;
    --primary-container-pressed: #805300;
    --primary-container-focused: #664200;
    --secondary: #aaacff;
    --on-secondary: #1a1b4d;
    --secondary-hover: #999cff;
    --secondary-pressed: #888bff;
    --secondary-focused: #777bff;
    --secondary-container: #666bff;
    --on-secondary-container: #ffffff;
    --secondary-container-hover: #555aff;
    --secondary-container-pressed: #4d51e5;
    --secondary-container-focused: #4448cc;
    --tertiary: #f2f2f3;
    --on-tertiary: #19191a;
    --tertiary-hover: #e5e5e6;
    --tertiary-pressed: #d8d8da;
    --tertiary-focused: #cbcbcd;
    --tertiary-container: #3e3e41;
    --on-tertiary-container: #ffffff;
    --tertiary-container-hover: #4a4a4f;
    --tertiary-container-pressed: #57575c;
    --tertiary-container-focused: #636369;
    --background: #454554;
    --on-background: #fafafa;
    --on-background-grey: #d5d5dc;
    --background-hover: #505062;
    --background-pressed: #5c5c70;
    --background-focused: #67677e;
    --background-container: #505062;
    --on-background-container: #f1f1f3;
    --on-background-container-grey: #b9b9c5;
    --background-container-hover: #5c5c70;
    --background-container-pressed: #67677e;
    --background-container-focused: #73738c;
    --surface: #4c4c67;
    --on-surface: #ffffff;
    --on-surface-grey: #d3d3de;
    --surface-hover: #575775;
    --surface-pressed: #626284;
    --surface-focused: #6c6c93;
    --surface-container: #21212c;
    --on-surface-container: #f0f0f4;
    --on-surface-container-grey: #b6b6c9;
    --surface-container-hover: #16161d;
    --surface-container-pressed: #0b0b0f;
    --surface-container-focused: #000000;
    --disabled-container: #73738c;
    --on-disabled-container: #d5d5dc;
    --error: #f28c8c;
    --on-error: #2e0505;
    --error-hover: #f07575;
    --error-pressed: #ed5e5e;
    --error-focused: #eb4747;
    --error-container: #b81414;
    --on-error-container: #fce8e8;
    --error-container-hover: #cf1717;
    --error-container-pressed: #e51a1a;
    --error-container-focused: #e83030;
    --warning: #f9e286;
    --on-warning: #302703;
    --warning-hover: #fae89e;
    --warning-pressed: #fbedb6;
    --warning-focused: #fcf3cf;
    --warning-container: #f2c40d;
    --on-warning-container: #302703;
    --warning-container-hover: #f4ca25;
    --warning-container-pressed: #f5d03d;
    --warning-container-focused: #f6d655;
    --info: #89b6f6;
    --on-info: #072c5f;
    --info-hover: #a0c5f7;
    --info-pressed: #b8d3f9;
    --info-focused: #d0e2fb;
    --info-container: #136eec;
    --on-info-container: #e7f0fd;
    --info-container-hover: #2a7dee;
    --info-container-pressed: #428bf0;
    --info-container-focused: #5a99f2;
    --okay: #99e5d9;
    --on-okay: #0f3d36;
    --okay-hover: #85e0d1;
    --okay-pressed: #70dbc9;
    --okay-focused: #5cd6c2;
    --okay-container: #29a38f;
    --on-okay-container: #ebfaf7;
    --okay-container-hover: #2eb8a1;
    --okay-container-pressed: #33ccb3;
    --okay-container-focused: #47d1ba;
}

// Colors here are independent of dark or light mode
body {
  --colors-text: #808080;
  --colors-primary: #ffa500;
  --colors-secondary: #555aff;
  --colors-tertiary: #808080;
  --colors-neutral: #73748c;
  --colors-neutral-variant: #a5a5bb;
  --colors-error: #ff2d37;
  --colors-warning: #ffd700;
  --colors-info: #0012b3;
  --colors-okay: #44cf90;
  --colors-white: #ffffff;
  --colors-black: #000000;
  --palettes-text-grey-colors-0: #000000;
  --palettes-text-grey-colors-50: #0d0d0d;
  --palettes-text-grey-colors-100: #1a1a1a;
  --palettes-text-grey-colors-150: #252525;
  --palettes-text-grey-colors-200: #333333;
  --palettes-text-grey-colors-300: #4d4d4d;
  --palettes-text-grey-colors-400: #666666;
  --palettes-text-grey-colors-500: #808080;
  --palettes-text-grey-colors-600: #999999;
  --palettes-text-grey-colors-700: #b3b3b3;
  --palettes-text-grey-colors-800: #cccccc;
  --palettes-text-grey-colors-900: #e6e6e6;
  --palettes-text-grey-colors-950: #f2f2f2;
  --palettes-text-grey-colors-1000: #ffffff;
  --palettes-conti-yellow-colors-0: #ffffff;
  --palettes-conti-yellow-colors-50: #fff6e5;
  --palettes-conti-yellow-colors-100: #ffedcc;
  --palettes-conti-yellow-colors-200: #ffdb99;
  --palettes-conti-yellow-colors-300: #ffc966;
  --palettes-conti-yellow-colors-400: #ffb733;
  --palettes-conti-yellow-colors-500: #ffa500;
  --palettes-conti-yellow-colors-600: #cc8400;
  --palettes-conti-yellow-colors-700: #996300;
  --palettes-conti-yellow-colors-800: #664200;
  --palettes-conti-yellow-colors-900: #332100;
  --palettes-conti-yellow-colors-950: #1a1100;
  --palettes-conti-yellow-colors-1000: #000000;
  --palettes-purple-colors-0: #ffffff;
  --palettes-purple-colors-50: #eeeeff;
  --palettes-purple-colors-100: #dddeff;
  --palettes-purple-colors-200: #bbbdff;
  --palettes-purple-colors-300: #999cff;
  --palettes-purple-colors-400: #777bff;
  --palettes-purple-colors-500: #555aff;
  --palettes-purple-colors-600: #4448cc;
  --palettes-purple-colors-700: #333699;
  --palettes-purple-colors-800: #222466;
  --palettes-purple-colors-900: #111233;
  --palettes-purple-colors-950: #080919;
  --palettes-purple-colors-1000: #000000;
  --palettes-tertiary-colors-0: #ffffff;
  --palettes-tertiary-colors-50: #f2f2f2;
  --palettes-tertiary-colors-100: #e6e6e6;
  --palettes-tertiary-colors-200: #cccccc;
  --palettes-tertiary-colors-300: #b3b3b3;
  --palettes-tertiary-colors-400: #999999;
  --palettes-tertiary-colors-500: #808080;
  --palettes-tertiary-colors-600: #666666;
  --palettes-tertiary-colors-700: #4d4d4d;
  --palettes-tertiary-colors-800: #333333;
  --palettes-tertiary-colors-900: #262626;
  --palettes-tertiary-colors-950: #0d0d0d;
  --palettes-tertiary-colors-1000: #000000;
  --palettes-neutral-colors-0: #ffffff;
  --palettes-neutral-colors-10: #fcfcfd;
  --palettes-neutral-colors-50: #f1f1f3;
  --palettes-neutral-colors-100: #e3e3e8;
  --palettes-neutral-colors-200: #c7c7d1;
  --palettes-neutral-colors-300: #abacba;
  --palettes-neutral-colors-400: #8f90a3;
  --palettes-neutral-colors-500: #73748c;
  --palettes-neutral-colors-600: #5c5d70;
  --palettes-neutral-colors-700: #454654;
  --palettes-neutral-colors-800: #2e2e38;
  --palettes-neutral-colors-900: #17171c;
  --palettes-neutral-colors-950: #0b0c0e;
  --palettes-neutral-colors-1000: #000000;
  --palettes-neutral-variant-colors-0: #ffffff;
  --palettes-neutral-variant-colors-50: #f6f6f8;
  --palettes-neutral-variant-colors-100: #ededf1;
  --palettes-neutral-variant-colors-200: #dbdbe4;
  --palettes-neutral-variant-colors-300: #c9c9d6;
  --palettes-neutral-variant-colors-400: #b7b7c9;
  --palettes-neutral-variant-colors-500: #a5a5bb;
  --palettes-neutral-variant-colors-600: #89899c;
  --palettes-neutral-variant-colors-700: #6d6d7d;
  --palettes-neutral-variant-colors-800: #50505e;
  --palettes-neutral-variant-colors-900: #34343f;
  --palettes-neutral-variant-colors-950: #26262f;
  --palettes-neutral-variant-colors-1000: #000000;
  --palettes-red-colors-0: #ffffff;
  --palettes-red-colors-50: #ffeaeb;
  --palettes-red-colors-100: #ffd5d7;
  --palettes-red-colors-200: #ffabaf;
  --palettes-red-colors-300: #ff8187;
  --palettes-red-colors-400: #ff575f;
  --palettes-red-colors-500: #ff2d37;
  --palettes-red-colors-600: #cc242c;
  --palettes-red-colors-700: #991b21;
  --palettes-red-colors-800: #661216;
  --palettes-red-colors-900: #33090b;
  --palettes-red-colors-1000: #000000;
  --palettes-yellow-colors-0: #ffffff;
  --palettes-yellow-colors-50: #fffbe5;
  --palettes-yellow-colors-100: #fff7cc;
  --palettes-yellow-colors-200: #ffef99;
  --palettes-yellow-colors-300: #ffe766;
  --palettes-yellow-colors-400: #ffdf33;
  --palettes-yellow-colors-500: #ffd700;
  --palettes-yellow-colors-600: #ccac00;
  --palettes-yellow-colors-700: #998100;
  --palettes-yellow-colors-800: #665600;
  --palettes-yellow-colors-900: #332b00;
  --palettes-yellow-colors-1000: #000000;
  --palettes-blue-colors-0: #ffffff;
  --palettes-blue-colors-50: #e5e7f7;
  --palettes-blue-colors-100: #ccd0f0;
  --palettes-blue-colors-200: #99a0e1;
  --palettes-blue-colors-300: #6671d1;
  --palettes-blue-colors-400: #3341c2;
  --palettes-blue-colors-500: #0012b3;
  --palettes-blue-colors-600: #000e8f;
  --palettes-blue-colors-700: #000b6b;
  --palettes-blue-colors-800: #000748;
  --palettes-blue-colors-900: #000424;
  --palettes-blue-colors-1000: #000000;
  --palettes-green-colors-0: #ffffff;
  --palettes-green-colors-50: #ecfaf4;
  --palettes-green-colors-100: #daf5e9;
  --palettes-green-colors-200: #b4ecd3;
  --palettes-green-colors-300: #8fe2bc;
  --palettes-green-colors-400: #69d9a6;
  --palettes-green-colors-500: #44cf90;
  --palettes-green-colors-600: #36a673;
  --palettes-green-colors-700: #297c56;
  --palettes-green-colors-800: #1b533a;
  --palettes-green-colors-900: #0e291d;
  --palettes-green-colors-1000: #000000;
}


