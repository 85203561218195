mat-chip-row {
  width: unset !important;
  height: 40px !important;
  line-height: 20px !important;
  letter-spacing: 0.25px !important;
  background-color: var(--secondary-container) !important;
  border-radius: 0 !important;

  button.mat-mdc-chip-remove {
    margin-bottom: unset;
  }
}

