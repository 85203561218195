mat-form-field.mat-form-field-disabled {
  mat-hint {
    color: var(--on-disabled-container);
  }
}

mat-form-field.mat-form-field-invalid {
  mat-hint {
    color: var(--error);
  }
}

.cdk-overlay-pane .language-panel[role="listbox"] {
  min-width: 39px;
  padding: 0;
  background-color: var(--background);
  color: var(--on-background);
  mat-option {
    font-size: 14px;
    padding-inline: 8px;
  }
}

app-landing-page .language-picker {
  span,
  .mat-mdc-select-arrow {
    color: var(--colors-white);
  }
}


/* style for filter for child job table */
.filter-container{
  mat-form-field .mat-mdc-form-field-bottom-align{
    display: none !important;
  }

  .mat-mdc-form-field div{
    border: none !important;
  }
}
